import React, { Component } from 'react';
import loading from 'assets/img/loading.svg';
import axios from "axios";
import Firebase from "../firebase";
import { navigate } from 'gatsby';
import { handleAuthentication, getCallbackLink, getAccessToken, isAuthenticated, getUserEmail } from 'Auth/AuthService';

class Callback extends Component {
  componentDidMount() {
    handleAuthentication();
    this.redirect();
  }

  redirect() {
    if (isAuthenticated()) {
      const email = getUserEmail();
      const headers = {
        Authorization: `Bearer ${getAccessToken()}`
      };
      axios
        .get(`https://us-east1-able-home-care-llc.cloudfunctions.net/api/auth/firebase`, { headers })
        .then(res => {
          Firebase.signInWithCustomToken(res.data.firebaseToken).then(() => {
            Firebase
              .collection("users")
              .doc(email)
              .onSnapshot(doc => {
                if (doc.data() === undefined || doc.data() === null) {
                  axios
                    .get(`https://us-east1-able-home-care-llc.cloudfunctions.net/api/users/new/${email}`,
                      {
                        headers
                      }
                    ).then(resp => {
                      Firebase
                        .collection("users")
                        .doc(resp.data.user.email)
                        .set(resp.data.user)
                        .then(() => {
                          navigate(getCallbackLink());
                        })
                    })
                }
                else {
                  navigate(getCallbackLink());
                }
              });
          });
        });
    } else {
      setTimeout(
        function() {
          this.redirect();
        }.bind(this),
        500
      );
    }
  }

  render() {
    return (
      <div style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: '98vh',
        width: '98vw',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
      }}
      >
        <img src={loading} alt="" />
      </div>
    );
  }
}

export default Callback;
